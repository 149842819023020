import { useTheme } from '../../../themes/theme.provider';

export interface LogInOptionComponentProps {
  setLogInOption: (option: LogInOption) => void;
}

export enum LogInOption {
  EMAIL = 'email',
  PHONE = 'phone',
}

const LogInOptionComponent: React.FC<LogInOptionComponentProps> = ({
  setLogInOption,
}) => {
  const { theme } = useTheme();

  return (
    <div
      className="position-relative"
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '768px',
        display: 'flex',
      }}
    >
      <div
        className={`rounded-16 bg-white p-4 text-nero z-1 mx-auto login--wrapper`}
        style={{
          width: '100%',
          maxWidth: '374px',
          marginTop: '16vh',
          borderRadius: 12,
        }}
      >
        <h3
          className="text-start mb-2"
          style={{ width: '100%', color: theme.black }}
        >
          {'Login via'}
        </h3>
        <div style={{ display: 'flex', marginTop: 24 }}>
          <div
            style={{
              flexGrow: 2,
              flexBasis: 0,
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              borderWidth: 1.5,
              borderColor: theme.borderColor,
              borderStyle: 'groove',
              padding: 8,
              borderRadius: 8,
            }}
            onClick={() => {
              setLogInOption(LogInOption.EMAIL);
            }}
          >
            <img
              src="/images/email_option.svg"
              height="32"
              className="logo-img"
              alt=""
              style={{
                width: '100%',
              }}
            />
            <p
              className="mb-2 pt-2"
              style={{ width: '100%', color: theme.black }}
            >
              Business Email
            </p>
          </div>
          <h5
            className="mb-2"
            style={{
              color: theme.black,
              flexGrow: 1,
              flexBasis: 0,
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            OR
          </h5>
          <div
            style={{
              flexGrow: 2,
              flexBasis: 0,
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              borderWidth: 1.5,
              borderColor: theme.borderColor,
              borderStyle: 'groove',
              padding: 8,
              borderRadius: 8,
              cursor: 'pointer',
            }}
            onClick={() => {
              setLogInOption(LogInOption.PHONE);
            }}
          >
            <img
              src="/images/mobile_option.svg"
              height="32"
              className="logo-img"
              alt=""
              style={{
                width: '100%',
              }}
            />
            <p
              className="mb-2 pt-2"
              style={{ width: '100%', color: theme.black }}
            >
              Phone No.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInOptionComponent;
