import { useEffect, useState } from 'react';
import { AppService } from '../../service/app.service';
import { AppRes } from '../../response/app.response';
import { AppSubscribeRequest } from '../../request/app.request';
import { Button, Spinner } from 'react-bootstrap';
import { SecurityManager } from '../../security/security-manager';
import { UserService } from '../../service/user.service';
import { UserRes } from '../../response/user.response';

const MyAppPage = () => {
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<UserRes>();

  const [myAppList, setMyAppList] = useState([]);
  const [allAppList, setAllAppList] = useState([]);

  const getAppList = async () => {
    setLoading(true);
    const [_appList, err] = await AppService.getAppList();
    const [_myAppList, err2] = await AppService.getMyApp();

    setMyAppList(_myAppList);
    setAllAppList(
      _appList.filter((app: AppRes) => {
        let include = true;
        _myAppList.map((_app: AppRes) => {
          if (_app.category == app.category) {
            include = false;
          }
        });
        return include;
      }),
    );
    setLoading(false);
  };

  const subscribeApp = async (app: AppRes) => {
    setLoading(true);
    const [_, err] = await AppService.subscribe({
      category: app.category,
    } as AppSubscribeRequest);

    setLoading(false);
    if (err) {
    } else {
      getAppList();
    }
  };

  const getUser = async () => {
    const [user, err] = await UserService.getUserById(SecurityManager.getUid());

    setUser(user);
  };

  useEffect(() => {
    getUser();
    getAppList();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <>
          <h4>Hello, {user?.userInfo?.name}</h4>
          {myAppList && myAppList.length > 0 && (
            <>
              <h4>My Apps</h4>
              {myAppList.map((app: AppRes) => {
                return <p>{app.category}</p>;
              })}
            </>
          )}
          {allAppList && allAppList.length > 0 && (
            <>
              <h4>More App</h4>
              {allAppList.map((app: AppRes) => {
                return (
                  <div>
                    <p>{app.category}</p>
                    <Button
                      onClick={() => {
                        subscribeApp(app);
                      }}
                    >
                      Subscribe
                    </Button>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyAppPage;
