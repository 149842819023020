import './auth.scss';
import { useEffect, useState } from 'react';
import { SecurityManager } from '../../security/security-manager';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useSearchParams } from 'react-router-dom';
import BackgroundComponent from './component/background.component';
import BusinessInfoComponent from './component/business-info.component';
import { useTheme } from '../../themes/theme.provider';
import OnBoardingComponent from './component/on-boarding.component';
import { Row, Col } from 'react-bootstrap';
import OnBoardingTab from './component/on-boarding-tab.component';

export enum OnBoardingPageTab {
  ON_BOARDING = 'ON_BOARDING',
  SEO_DATA = 'SEO_DATA',
}

export interface OnBoardingPageProp {
  _email?: string | null;
  _phone?: string | null;
}

const OnBoardingPage = () => {
  const [searchParams] = useSearchParams();
  const { theme } = useTheme();

  const email = searchParams.get('email');
  const phone = searchParams.get('phone');

  const [activeTab, setActiveTab] = useState(OnBoardingPageTab.ON_BOARDING);

  const { openHome } = useSSNavigate();
  const loggedIn = SecurityManager.loggedIn();

  useEffect(() => {
    if (loggedIn) {
      openHome();
    }
  }, []);

  return (
    <div
      style={{
        height: '100vh',
      }}
      className="position-relative"
    >
      <BackgroundComponent />

      <div
        className="position-absolute top-0 left-0"
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
        }}
      >
        <div style={{ width: '46%' }}>
          <BusinessInfoComponent showLogIn={true} />
        </div>

        <div style={{ width: '54%', backgroundColor: theme.white }}>
          {/* <h3
            className="text-start mb-2"
            style={{ width: '100%', padding: 36, color: theme.black }}
          >
            Welcome
          </h3> */}
          {/* <Row className="pb-4 mx-lg-3 mx-1">
            <Col xs={12}>
              <OnBoardingTab activeTab={activeTab} />
            </Col>
          </Row> */}
          <Row className="mx-2">
            <Col xs={12} className="border-top border-lightgray pt-4 px-0">
              <div className="mx-xl-5 px-xl-5 mx-lg-3 px-lg-3 py-2">
                {activeTab == OnBoardingPageTab.ON_BOARDING ? (
                  <OnBoardingComponent _email={email} _phone={phone} />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingPage;
