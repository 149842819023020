import { AppSubscribeRequest } from '../request/app.request';
import { UserRequest, UserSearchReq } from '../request/user.request';
import { AppRes } from '../response/app.response';
import { UserConfig, UserRes } from '../response/user.response';
import httpClient from '../util/http-client';

export const AppService = {
  subscribe: async (
    appSubscribeRequest: AppSubscribeRequest,
  ): Promise<(AppRes | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.post('/app/subscribe', appSubscribeRequest);
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getMyApp: async (): Promise<(AppRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get('/my-app');
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
  getAppList: async (): Promise<(AppRes[] | any)[]> => {
    let data;
    let err;
    try {
      const res = await httpClient.get('/app');
      data = res.data;
    } catch (error: any) {
      err = error;
    }
    return [data?.data, err];
  },
};
