import './auth.scss';
import { useEffect, useState } from 'react';
import ForgotPassComponent from './component/forgot-pass.component';
import ReSetPassComponent from './component/reset-pass.component';
import { SecurityManager } from '../../security/security-manager';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';
import { useSearchParams } from 'react-router-dom';
import BackgroundComponent from './component/background.component';
import LogInComponent from './component/log-in.component';
import { useTheme } from '../../themes/theme.provider';
import { Button } from 'react-bootstrap';
import LogInOptionComponent, {
  LogInOption,
} from './component/login-option.component';
import SignUpComponent from './component/sign-up.component';
import BusinessInfoComponent from './component/business-info.component';

export enum LogInPageState {
  SIGN_UP = 'SIGN_UP',
  OPTION_SELECTION = 'OPTION_SELECTION',
  NORMAL = 'NORMAL',
  SEND_FORGOT_LINK = 'SEND_FORGOT_LINK',
  RESET_PASS = 'RESET_PASS',
}

export interface LogInPageProp {
  updatePageState: (newAuthPageState: LogInPageState) => void;
  logInOption?: LogInOption;
}

const LogInPage = () => {
  const [searchParams] = useSearchParams();
  const { theme } = useTheme();

  const token = searchParams.get('forgot_pass_token');

  const [authPageState, setAuthPageState] = useState(
    token && token.length > 10
      ? LogInPageState.RESET_PASS
      : LogInPageState.OPTION_SELECTION,
  );
  const [logInOption, setLogInOption] = useState(LogInOption.EMAIL);

  const { openHome } = useSSNavigate();
  const loggedIn = SecurityManager.loggedIn();

  useEffect(() => {
    if (loggedIn) {
      openHome();
    }
  }, []);

  const updatePageState = (newAuthPageState: LogInPageState) => {
    setAuthPageState(newAuthPageState);
  };

  return (
    <div
      style={{
        height: '100vh',
      }}
      className="position-relative"
    >
      <BackgroundComponent />

      <div
        className="position-absolute top-0 left-0"
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
        }}
      >
        <div style={{ width: '50%' }}>
          <BusinessInfoComponent showLogIn={false} />
        </div>

        <div style={{ width: '50%' }}>
          {authPageState == LogInPageState.SIGN_UP ? (
            <div className="text-end" style={{ width: '100%', padding: 36 }}>
              <span
                className="mb-2"
                style={{ color: theme.white, borderWidth: 0 }}
              >
                Already have an Account?
              </span>
              <Button
                style={{
                  backgroundColor: theme.white,
                  borderWidth: 2,
                  marginLeft: 16,
                  borderColor: theme.borderColor,
                }}
                onClick={() => {
                  updatePageState(LogInPageState.OPTION_SELECTION);
                }}
              >
                Log In
              </Button>
            </div>
          ) : (
            <div className="text-end" style={{ width: '100%', padding: 36 }}>
              <span
                className="mb-2"
                style={{ color: theme.white, borderWidth: 0 }}
              >
                Create new Account
              </span>
              <Button
                style={{
                  backgroundColor: theme.white,
                  borderWidth: 2,
                  marginLeft: 16,
                  borderColor: theme.borderColor,
                }}
                onClick={() => {
                  updatePageState(LogInPageState.SIGN_UP);
                }}
              >
                Sign Up
              </Button>
            </div>
          )}
          {authPageState == LogInPageState.OPTION_SELECTION && (
            <LogInOptionComponent
              setLogInOption={(logInOption: LogInOption) => {
                setLogInOption(logInOption);
                updatePageState(LogInPageState.NORMAL);
              }}
            />
          )}
          {authPageState == LogInPageState.NORMAL && (
            <LogInComponent
              updatePageState={updatePageState}
              logInOption={logInOption}
            />
          )}
          {authPageState == LogInPageState.SIGN_UP && <SignUpComponent />}
          {authPageState == LogInPageState.SEND_FORGOT_LINK && (
            <ForgotPassComponent updatePageState={updatePageState} />
          )}
          {authPageState == LogInPageState.RESET_PASS && (
            <ReSetPassComponent updatePageState={updatePageState} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
