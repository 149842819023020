import * as React from 'react';
import { Navigate, RouteProps } from 'react-router';
import { SecurityManager } from './security-manager';

export const SecureRoute: React.FC<RouteProps> = (props) => {
  const { element } = props;

  const loggedIn = SecurityManager.loggedIn();

  if (!loggedIn) {
    let redirectUrl = '/log-in';
    return <Navigate to={redirectUrl} />;
  }
  return element as JSX.Element;
};
