import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n/i18n.key';
import { useTheme } from '../../themes/theme.provider';

const FooterComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <>
      <div className="px-4 py-3 d-flex align-items-center justify-content-between ft-14">
        <div className="d-flex align-items-center">
          <span style={{ color: theme.themeSecondary }} className="me-2">
            {t(I18nKey.SASSY_COPYRIGHT_TEXT)}
          </span>
          <img src="/images/wifi-icon.svg" height={24} alt="" />
        </div>
        <div className="d-flex align-items-center mx-3">
          <span style={{ color: theme.themeSecondary }} className="me-1">
            {t(I18nKey.NEED_HELP_CONTACT_US_TEXT)}:
          </span>
          <span
            style={{ color: theme.themeSecondary }}
            className="font-weight-700"
          >
            +91 99876 54321
          </span>
        </div>
        {/* <div className="d-flex align-items-center">
          <span style={{ color: theme.themeSecondary }} className="me-1">
            {t(I18nKey.POWRED_BY_TEXT)}
          </span>
          <img src="/images/sassy-dark.svg" height={24} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default FooterComponent;
