import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../../i18n/i18n.key';
import { Session } from '../../../model/session.model';
import { SendOtpReq, SignInReq } from '../../../request/auth.request';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { useSnackbar } from 'notistack';
import { useTheme } from '../../../themes/theme.provider';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

const SignUpComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const { openOnBoarding } = useSSNavigate();

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (email.length > 0 && !validator.isEmail(email)) {
      _errors.email = 'Please enter valid email';
    }

    if (phone.length > 0 && !validator.isMobilePhone(phone)) {
      _errors.phone = 'Please enter valid phone';
    }

    if (email.length == 0 && phone.length == 0) {
      _errors.email = 'Please enter valid email';
      _errors.phone = 'Please enter valid phone';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      sendOtp();
    }
  };

  const sendOtp = async () => {
    const [data, err] = await AuthService.sendOtp({
      email: email,
      phone: phone,
    } as SendOtpReq);
    setLoading(false);
    if (err) {
    } else {
      openOnBoarding(email, phone);
    }
  };

  return (
    <div
      className="position-relative"
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '768px',
        display: 'flex',
      }}
    >
      <div
        className="rounded-16 bg-white p-4 login--wrapper text-nero z-1 mx-auto"
        style={{
          width: '100%',
          maxWidth: '374px',
          marginTop: '16vh',
          borderRadius: 12,
        }}
      >
        <h5 className="mb-4 text-nero">{'Sign Up'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{'Business Email ID'}</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="email"
                  value={email}
                  isInvalid={!!errors.email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`ft-14 px-2 shadow-none border ${
                    errors.email ? 'border-danger' : ''
                  } `}
                  placeholder={'Type your business email'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid email
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <h5
              className="mb-2"
              style={{
                color: theme.black,
                flexGrow: 1,
                flexBasis: 0,
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              OR
            </h5>
            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>{'Phone no.'}</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="phone"
                  value={phone}
                  isInvalid={!!errors.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className={`ft-14 px-2 shadow-none border ${
                    errors.phone ? 'border-danger' : ''
                  } `}
                  placeholder={'Type your phone no.'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid phone
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button
              type="submit"
              style={{ backgroundColor: theme.primaryColor, width: '100%' }}
              className="text-white ft-14 font-weight-600  mt-2"
            >
              {'Receive OTP'}
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default SignUpComponent;
