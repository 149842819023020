import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../../i18n/i18n.key';
import { Session } from '../../../model/session.model';
import { SignInReq } from '../../../request/auth.request';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { LogInPageState, LogInPageProp } from '../login.page';
import { useSnackbar } from 'notistack';
import { useTheme } from '../../../themes/theme.provider';
import { LogInOption } from './login-option.component';

const LogInComponent: React.FC<LogInPageProp> = ({
  updatePageState,
  logInOption,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [errors, setErrors] = useState({
    email: '',
    phone: '',
    password: '',
  });
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const signIn = async () => {
    const [data, err] = await AuthService.signIn({
      id: logInOption == LogInOption.PHONE ? phone : email,
      password: password,
    } as SignInReq);

    if (data && data.refreshToken && data.refreshToken.length > 0) {
      enqueueSnackbar(t(I18nKey.LOG_IN_SUCCESS), {
        variant: 'success',
      });
      SecurityManager.setSession({
        userId: data.userId,
        refreshToken: data.refreshToken,
      } as Session);
    } else {
      const _errors: any = {};
      _errors.id = 'Please enter valid id';
      _errors.password = 'Please enter valid password';
      setErrors(_errors);
      setValidated(false);
      setLoading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (logInOption == LogInOption.EMAIL && !validator.isEmail(email)) {
      _errors.email = 'Please enter valid email';
    }

    if (logInOption == LogInOption.PHONE && !validator.isMobilePhone(phone)) {
      _errors.phone = 'Please enter valid phone';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      signIn();
    }
  };

  return (
    <div
      className="position-relative"
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '768px',
        display: 'flex',
      }}
    >
      <div
        className="rounded-16 bg-white p-4 login--wrapper text-nero z-1 mx-auto"
        style={{
          width: '100%',
          maxWidth: '374px',
          marginTop: '16vh',
          borderRadius: 12,
        }}
      >
        <h5 className="mb-4 text-nero">{'Login to your Account'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {logInOption == LogInOption.PHONE ? (
              <Form.Group controlId="phone" className="mb-3">
                <Form.Label>{'Phone Number'}</Form.Label>

                <InputGroup hasValidation className="mb-3">
                  <Form.Control
                    required
                    type="phone"
                    value={phone}
                    isInvalid={!!errors.phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className={`ft-14 px-2 shadow-none border ${
                      errors.phone ? 'border-danger' : ''
                    } `}
                    placeholder={'Enter your phone'}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid phone
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            ) : (
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>{'Email Address'}</Form.Label>

                <InputGroup hasValidation className="mb-3">
                  <Form.Control
                    required
                    type="email"
                    value={email}
                    isInvalid={!!errors.email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`ft-14 px-2 shadow-none border ${
                      errors.email ? 'border-danger' : ''
                    } `}
                    placeholder={'Enter your email'}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid email
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
            <Form.Group controlId="password">
              <Form.Label>{t(I18nKey.PASSWORD_KEY_TEXT)}</Form.Label>
              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="password"
                  value={password}
                  isInvalid={!!errors.password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`ft-14 px-2 shadow-none ${
                    errors.password ? 'border-danger' : ''
                  } `}
                  placeholder={t(I18nKey.ENTER_YOUR_PASSCODE_TEXT)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button
              type="submit"
              style={{ backgroundColor: theme.primaryColor, width: '100%' }}
              className="text-white ft-14 font-weight-600  mt-2"
            >
              {t(I18nKey.LOGIN_TEXT)}
            </Button>
            <div className="text-start mt-2">
              <span className="mb-2">Forgot Password?</span>
              <Button
                variant="link"
                style={{ color: theme.primaryColor }}
                onClick={() => updatePageState(LogInPageState.SEND_FORGOT_LINK)}
              >
                Click here
              </Button>
            </div>

            <Button
              variant="link"
              style={{ color: theme.primaryColor }}
              onClick={() => updatePageState(LogInPageState.OPTION_SELECTION)}
            >
              Try another method
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default LogInComponent;
