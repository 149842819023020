import { useState } from 'react';
import { MenuItem } from '../../../model/menu-item.model';
import { useTheme } from '../../../themes/theme.provider';
import { useWindow } from '../../../util/window-helper';
import MenuItemComponent from './menu-item.component';
import CollapsedMenuItemComponent from './collapsed-menu-item';
import { SecurityManager } from '../../../security/security-manager';

const HeaderComponent = () => {
  const { isMobile } = useWindow();

  const { theme } = useTheme();
  const [collapsed, setCollapsed] = useState(isMobile);

  const menuList = [
    {
      id: 'my-app',
      name: 'My App',
      icon: 'app',
      path: '/my-app',
      subMenuList: [],
    } as MenuItem,
  ];

  const settingMenuList = [
    {
      id: 'settings',
      name: 'Settings',
      icon: 'settings',
      path: '',
      subMenuList: [
        {
          id: 'logout',
          name: 'Logout',
          icon: 'log-out.svg',
          path: '',
          action: () => {
            SecurityManager.logout();
          },
          subMenuList: [],
        } as MenuItem,
      ],
    } as MenuItem,
  ];

  return (
    <>
      {collapsed ? (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 84,
            height: '100vh',
            padding: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
          </div>

          <div
            style={{
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <CollapsedMenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: '100%',
              marginTop: 16,
              cursor: 'pointer',
            }}
          >
            {settingMenuList.map((menuItem: MenuItem, index: number) => {
              return (
                <CollapsedMenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
            {/* <img
              src="/images/settings.svg"
              alt=""
              width={64}
              height={64}
              style={{ padding: 16 }}
            /> */}
          </div>
          <img
            src="/images/open_menu.svg"
            alt=""
            width={64}
            height={64}
            style={{
              padding: 16,
              cursor: 'pointer',
            }}
            onClick={() => {
              setCollapsed(false);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: theme.primaryColor,
            width: 284,
            height: '100vh',
            padding: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              width: '100%',
            }}
          >
            <img src="/images/saissy_logo.svg" alt="" width={64} height={30} />
            <img
              src="/images/close_menu.svg"
              alt=""
              width={32}
              height={32}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCollapsed(true);
              }}
            />
          </div>

          <div
            style={{
              height: `calc(100% - 100px)`,
              marginTop: 16,
              overflow: 'auto',
            }}
          >
            {menuList.map((menuItem: MenuItem, index: number) => {
              return (
                <MenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignContent: 'start',
              width: 64,
              marginBottom: 16,
              position: 'absolute',
              bottom: 0,
            }}
          >
            {settingMenuList.map((menuItem: MenuItem, index: number) => {
              return (
                <CollapsedMenuItemComponent
                  isFirst={index == 0}
                  isLast={index == menuList.length - 1}
                  menuItem={menuItem}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderComponent;
