import { useEffect, useRef, useState } from 'react';
import { useTheme } from '../../../themes/theme.provider';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';
import { MenuItemComponentProp } from './menu-item.component';
import { MenuItem } from '../../../model/menu-item.model';

const CollapsedMenuItemComponent: React.FC<MenuItemComponentProp> = ({
  isFirst,
  isLast,
  menuItem,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { theme } = useTheme();

  const { openPath } = useSSNavigate();

  const ref = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={`rounded`}
      style={{
        backgroundColor: expanded ? '#1A529C' : theme.secondaryColor,
        width: '100%',
        marginTop: 4,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'space-between',
          width: '100%',
          cursor: 'pointer',
          background:
            window.location.pathname.includes(menuItem.path) &&
            menuItem.subMenuList.length == 0
              ? '#4D79B2'
              : '',
        }}
        onClick={() => {
          if (menuItem.subMenuList.length > 0) {
            setExpanded((preState) => !preState);
          } else {
            setExpanded(false);
            if (menuItem.action) {
              menuItem.action();
            } else {
              openPath(menuItem.path);
            }
          }
        }}
      >
        <img
          src={`/images/${menuItem.icon}.svg`}
          alt=""
          width={64}
          height={64}
          style={{
            padding: 20,
          }}
        />
      </div>

      {expanded && (
        <div
          ref={ref}
          className="rounded"
          style={{
            position: 'absolute',
            background: theme.secondaryColor,
            left: 84,
            marginTop: -74,
          }}
        >
          {menuItem.subMenuList.map((subMenuItem: MenuItem, index: number) => {
            return (
              <div
                className={`${index == 0 ? 'rounded-top' : ''} ${
                  index == menuItem.subMenuList.length - 1
                    ? 'rounded-bottom'
                    : ''
                }`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignContent: 'start',
                  width: '100%',
                  padding: 8,
                  paddingTop: 16,
                  paddingRight: 16,
                  cursor: 'pointer',
                  position: 'relative',
                  zIndex: 2,
                  backgroundColor: window.location.pathname.includes(
                    subMenuItem.path,
                  )
                    ? '#4D79B2'
                    : theme.secondaryColor,
                }}
                onClick={() => {
                  setExpanded(false);
                  if (subMenuItem.action) {
                    subMenuItem.action();
                  } else {
                    openPath(`${menuItem.path}${subMenuItem.path}`);
                  }
                }}
              >
                <img
                  src={`/images/${subMenuItem.icon}`}
                  alt=""
                  width={20}
                  height={20}
                  style={{ marginLeft: 8 }}
                />
                <h6 style={{ color: theme.white, marginLeft: 16 }}>
                  {subMenuItem.name}
                </h6>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CollapsedMenuItemComponent;
