import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n/i18n.key';
import { useTheme } from '../../../themes/theme.provider';
import { Button } from 'react-bootstrap';
import { useSSNavigate } from '../../../navigation/ss-navigate.hook';

export interface BusinessInfoComponentProps {
  showLogIn: boolean;
}
const BusinessInfoComponent: React.FC<BusinessInfoComponentProps> = ({
  showLogIn,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { openLogIn } = useSSNavigate();

  return (
    <div
      className="p-4 position-relative top-0 left-0"
      style={{
        width: '100%',
        height: '100%',
        maxWidth: '768px',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'inline-flex',
          alignItems: 'flex-start',
          marginTop: 16,
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img src="/images/logo.svg" height="36" className="logo-img" alt="" />
          <img
            src="/images/globe-icon.svg"
            height="24"
            className="logo-img"
            alt=""
            style={{ marginLeft: 24 }}
          />
          <p
            style={{
              marginLeft: 16,
              marginRight: 4,
              color: theme.white,
              marginTop: 16,
            }}
          >
            English (US)
          </p>
          <img
            src="/images/arrow-down.svg"
            height="16"
            className="logo-img"
            alt=""
          />
        </div>
        {showLogIn && (
          <div className="text-end mt-2" style={{ width: '100%' }}>
            <span
              className="mb-2"
              style={{ color: theme.white, borderWidth: 0 }}
            >
              Already have an Account?
            </span>
            <Button
              style={{
                backgroundColor: theme.white,
                borderWidth: 2,
                marginLeft: 16,
                borderColor: theme.borderColor,
              }}
              onClick={openLogIn}
            >
              Log In
            </Button>
          </div>
        )}
      </div>

      <h1
        className="text-bold"
        style={{
          color: theme.white,
          width: 374,
          marginLeft: '20%',
          paddingTop: '20vh',
        }}
      >
        Simplify your Business.
      </h1>
      <p
        className="text-2xl"
        style={{
          color: theme.white,
          paddingLeft: '20%',
          width: 374,
        }}
      >
        One platform serving you with content management, ed-tech and
        collaboration tools.
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '20%',
          marginTop: 24,
        }}
      >
        <img
          src="/images/call-icon.svg"
          height="36"
          className="logo-img"
          alt=""
        />
        <div style={{ marginLeft: 16 }}>
          <p style={{ color: theme.white }}>Call us for a Demo</p>
          <p style={{ color: theme.white }}>+91 99876 54321</p>
        </div>
      </div>

      <div
        className="text-end mt-2"
        style={{
          width: '100%',
          position: 'absolute',
          bottom: 24,
          right: 24,
          color: theme.white,
        }}
      >
        <span>© 2024 Box Inc.</span>
        <Button
          variant="link"
          style={{
            color: theme.white,
          }}
          onClick={() => {}}
        >
          Privacy Policy
        </Button>
        <Button
          variant="link"
          style={{
            color: theme.white,
          }}
          onClick={() => {}}
        >
          Terms of Service
        </Button>
        <Button
          variant="link"
          style={{
            color: theme.white,
          }}
          onClick={() => {}}
        >
          FAQ
        </Button>
      </div>
    </div>
  );
};

export default BusinessInfoComponent;
