export enum I18nKey {
  DONOT_WAIT_LEARN_TEXT = 'donot_wait_learn',
  GRAB_OPPORTUNITY_NOW_TEXT = 'grab_opportunity_now',
  STUDENT_LOGIN_TEXT = 'student_login',
  STUDENT_ID_TEXT = 'student_id',
  ENTER_YOUR_ID_TEXT = 'enter_your_id',
  PASSWORD_KEY_TEXT = 'password',
  ENTER_YOUR_PASSCODE_TEXT = 'enter_your_passcode',
  LOGIN_TEXT = 'login',
  ADMIN_ID_TEXT = 'admin_id_key',
  ADMIN_KEY = 'admin_key',
  LOG_IN_SUCCESS = 'log_in_success',
  FOR_GOT_PASS_LINK_SENT = 'for_got_pass_link_sent',
  LINK_COPIED = 'link_copied',
  SASSY_COPYRIGHT_TEXT = 'sassy_copyright',
  NEED_HELP_CONTACT_US_TEXT = 'need_help_contact_us',
  POWRED_BY_TEXT = 'powered_by',
}
