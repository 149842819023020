import { useTranslation } from 'react-i18next';
import { useTheme } from '../../themes/theme.provider';
import { useEffect } from 'react';
import { useSSNavigate } from '../../navigation/ss-navigate.hook';

const HomePage = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const { openMyApp } = useSSNavigate();

  useEffect(() => {
    openMyApp();
  }, []);

  return (
    <>
      <h1>Home Page</h1>
    </>
  );
};

export default HomePage;
