import { useNavigate } from 'react-router';

export const useSSNavigate = () => {
  const navigate = useNavigate();

  const openLogIn = () => {
    navigate('/log-in', { replace: true });
  };

  const openOnBoarding = (email: string, phone: string) => {
    navigate(
      `/on-board?${email ? 'email=' + encodeURIComponent(email) : ''}${
        email && phone ? '&' : ''
      }${phone ? 'phone=' + encodeURIComponent(phone) : ''}`,
      { replace: true },
    );
  };

  const openHome = () => {
    navigate(`/`, { replace: true });
  };

  const openMyApp = () => {
    navigate(`/my-app`, { replace: true });
  };

  const openPath = (path: string) => {
    navigate(path, { replace: false });
  };

  return {
    openLogIn,
    openOnBoarding,
    openHome,
    openMyApp,
    openPath,
  };
};
