import { createBrowserRouter } from 'react-router-dom';
import { SecureRoute } from '../security/secure.route';
import MainHolder from '../page/main.holder';
import LogInPage from '../page/auth/login.page';
import HomePage from '../page/home/home.page';
import ProfilePage from '../page/profile/profile.page';
import OnBoardingPage from '../page/auth/onboarding.page';
import MyAppPage from '../page/my-app/my-app.page';

export const mainRoute = createBrowserRouter([
  {
    path: '/log-in',
    element: <LogInPage />,
    children: [],
  },
  {
    path: '/on-board',
    element: <OnBoardingPage />,
    children: [],
  },
  {
    element: <MainHolder />,
    children: [
      {
        path: '',
        element: <SecureRoute element={<HomePage />} />,
        children: [],
      },
      {
        path: '/my-app',
        element: <SecureRoute element={<MyAppPage />} />,
        children: [],
      },
      {
        path: '/profile',
        element: <SecureRoute element={<ProfilePage />} />,
        children: [],
      },
    ],
  },
  {
    path: '*',
    element: <h1>Page not found</h1>,
  },
]);
