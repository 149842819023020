import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../../i18n/i18n.key';
import { Session } from '../../../model/session.model';
import { ValidateOtpReq } from '../../../request/auth.request';
import { SecurityManager } from '../../../security/security-manager';
import { AuthService } from '../../../service/auth.service';
import { useSnackbar } from 'notistack';
import { useTheme } from '../../../themes/theme.provider';
import { OnBoardingPageProp } from '../onboarding.page';
import { UserInfo } from '../../../model/user-info.model';
import { BusinessInfo } from '../../../model/business-info.model';

const OnBoardingComponent: React.FC<OnBoardingPageProp> = ({
  _email,
  _phone,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
    name: '',
    businessName: '',
    otp: '',
  });
  const [email, setEmail] = useState(_email ?? '');
  const [phone, setPhone] = useState(_phone ?? '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [otp, setOtp] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (email.length > 0 && !validator.isEmail(email)) {
      _errors.email = 'Please enter valid email';
    }

    if (phone.length > 0 && !validator.isMobilePhone(phone)) {
      _errors.phone = 'Please enter valid phone';
    }

    if (!validator.isLength(name, { min: 4, max: 30 })) {
      _errors.name = 'Please enter valid name';
    }

    if (!validator.isLength(businessName, { min: 4, max: 30 })) {
      _errors.businessName = 'Please enter valid business name';
    }

    if (!validator.isLength(otp, { min: 4, max: 4 })) {
      _errors.otp = 'Please enter valid otp';
    }

    if (!validator.isLength(newPassword, { min: 4, max: 30 })) {
      _errors.newPassword = 'Please enter valid password';
    }

    if (newPassword != confirmPassword) {
      _errors.confirmPassword = 'Please enter valid password';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      onBoardUser();
    }
  };
  const onBoardUser = async () => {
    const [data, err] = await AuthService.validateOtp({
      phone: phone,
      email: email,
      otp: otp,
      password: newPassword,
      userInfo: {
        name: name,
        phone: phone,
        email: email,
      } as UserInfo,
      businessInfo: {
        name: businessName,
      } as BusinessInfo,
    } as ValidateOtpReq);

    if (data && data.refreshToken && data.refreshToken.length > 0) {
      enqueueSnackbar(t(I18nKey.LOG_IN_SUCCESS), {
        variant: 'success',
      });
      SecurityManager.setSession({
        userId: data.userId,
        refreshToken: data.refreshToken,
      } as Session);
    } else {
      const _errors: any = {};
      _errors.id = 'Please enter valid ID';
      _errors.password = 'Please enter valid password';
      setErrors(_errors);
      setValidated(false);
      setLoading(false);
    }
  };

  return (
    <div
      className="position-relative"
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '768px',
        display: 'flex',
      }}
    >
      <div
        className="rounded-16 bg-white text-nero z-1 mx-auto"
        style={{
          width: '100%',
          borderRadius: 12,
        }}
      >
        <h5 className="mb-4 text-nero">{'Welcome to saissy!'}</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {_phone && (
              <Form.Group controlId="phone" className="mb-3">
                <Form.Label>Enter Phone</Form.Label>

                <InputGroup hasValidation className="mb-3">
                  <Form.Control
                    required
                    type="phone"
                    value={phone}
                    readOnly
                    isInvalid={!!errors.phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className={`ft-14 px-1 shadow-none border ${
                      errors.phone ? 'border-danger' : ''
                    } `}
                    placeholder={'Enter Your Phone'}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid phone
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
            {_email && (
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Enter Email</Form.Label>

                <InputGroup hasValidation className="mb-3">
                  <Form.Control
                    required
                    type="email"
                    readOnly
                    value={email}
                    isInvalid={!!errors.email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`ft-14 px-1 shadow-none border ${
                      errors.email ? 'border-danger' : ''
                    } `}
                    placeholder={'Enter Your Email'}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid email
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Full Name</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="name"
                  value={name}
                  isInvalid={!!errors.name}
                  onChange={(e) => setName(e.target.value)}
                  className={`ft-14 px-1 shadow-none border ${
                    errors.name ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Your name'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="businessName" className="mb-3">
              <Form.Label>Enter Business Name</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="businessName"
                  value={businessName}
                  isInvalid={!!errors.businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className={`ft-14 px-1 shadow-none border ${
                    errors.businessName ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Your Business Name'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid business name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="otp" className="mb-3">
              <Form.Label>Enter Otp</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="otp"
                  value={otp}
                  isInvalid={!!errors.otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className={`ft-14 px-1 shadow-none border ${
                    errors.otp ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Your otp'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid otp
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="string"
                  value={newPassword}
                  isInvalid={!!errors.newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={`ft-14 px-1 shadow-none ${
                    errors.newPassword ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter New Password'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="password"
                  value={confirmPassword}
                  isInvalid={!!errors.confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`ft-14 px-1 shadow-none ${
                    errors.confirmPassword ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Confirm Password'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Check
                required
                label={
                  <p className="text-start" style={{ marginTop: 0 }}>
                    By selecting, I accept
                    <Button
                      variant="link"
                      style={{
                        color: theme.primaryColor,
                        paddingTop: 0,
                        paddingBottom: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}
                    >
                      Terms of Service
                    </Button>
                    and
                    <Button
                      variant="link"
                      style={{
                        color: theme.primaryColor,
                        paddingTop: 0,
                        paddingBottom: 2,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}
                    >
                      Privacy Policy.
                    </Button>
                  </p>
                }
                feedback="You must agree before submitting."
                feedbackType="invalid"
              />
            </Form.Group>
            <Button
              type="submit"
              style={{ backgroundColor: theme.primaryColor, width: '100%' }}
              className="text-white font-weight-600  mt-2"
            >
              {'Continue'}
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default OnBoardingComponent;
