import { Outlet } from 'react-router';
import { useWindow } from '../util/window-helper';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import HeaderComponent from './shared/header/header.component';
import FooterComponent from './shared/footer.component';
import { UserService } from '../service/user.service';
import { SecurityManager } from '../security/security-manager';
import { useTheme } from '../themes/theme.provider';

const MainHolder = () => {
  const { isMobile } = useWindow();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    UserService.getUserById(SecurityManager.getUid());
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          style={{
            marginTop: 48,
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          animation="grow"
        />
      ) : (
        <div
          style={{
            position: 'relative',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'start',
            alignContent: 'start',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <HeaderComponent />
          <div
            style={{
              backgroundColor: theme.white,
              height: '100vh',
              width: '100%',
              padding: 16,
              overflow: 'auto',
            }}
          >
            <div
              style={{
                minHeight: '92vh',
              }}
            >
              <Outlet />
            </div>

            <FooterComponent />
          </div>
        </div>
      )}
    </>
  );
};

export default MainHolder;
