import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import validator from 'validator';
import empty from 'is-empty';
import { I18nKey } from '../../../i18n/i18n.key';
import { AuthService } from '../../../service/auth.service';
import { LogInPageState, LogInPageProp } from '../login.page';
import { useSearchParams } from 'react-router-dom';
import { ReSetPassReq } from '../../../request/reset.request';
import { useTheme } from '../../../themes/theme.provider';

const ReSetPassComponent: React.FC<LogInPageProp> = ({ updatePageState }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const token = searchParams.get('forgot_pass_token');

  const [errors, setErrors] = useState({
    phone: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetPassword = async () => {
    const [data, err] = await AuthService.reSetPass({
      phone: phone,
      password: newPassword,
      token: token,
    } as ReSetPassReq);

    if (err) {
      const _errors: any = {};
      _errors.id = 'Please enter valid ID';
      _errors.newPassword = 'Please enter valid password';
      setErrors(_errors);
      setValidated(false);
      setLoading(false);
    } else {
      updatePageState(LogInPageState.NORMAL);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const _errors: any = {};

    // validation
    if (!validator.isLength(phone, { min: 10, max: 10 })) {
      _errors.id = 'Please enter valid phone';
    }

    if (!validator.isLength(newPassword, { min: 4, max: 30 })) {
      _errors.newPassword = 'Please enter valid password';
    }

    if (newPassword != confirmPassword) {
      _errors.confirmPassword = 'Please enter valid password';
    }

    if (!empty(_errors)) {
      setErrors(_errors);
      setValidated(false);
    } else {
      setLoading(true);
      setValidated(true);
      resetPassword();
    }
  };

  return (
    <div
      className="position-relative"
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '768px',
        display: 'flex',
      }}
    >
      <div
        className="rounded-16 bg-white p-4 login--wrapper text-nero z-1 mx-auto"
        style={{
          width: '100%',
          maxWidth: '374px',
          marginTop: '16vh',
          borderRadius: 12,
        }}
      >
        <h5 className="mb-4 text-nero">Change Password</h5>
        {loading ? (
          <Spinner
            style={{
              marginTop: 48,
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            animation="grow"
          />
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="id" className="mb-3">
              <Form.Label>Enter Phone</Form.Label>

              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="text"
                  value={phone}
                  isInvalid={!!errors.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className={`ft-14 px-1 shadow-none border ${
                    errors.phone ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Your Phone'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid phone
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="string"
                  value={newPassword}
                  isInvalid={!!errors.newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={`ft-14 px-1 shadow-none ${
                    errors.newPassword ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter New Password'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup hasValidation className="mb-3">
                <Form.Control
                  required
                  type="password"
                  value={confirmPassword}
                  isInvalid={!!errors.confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`ft-14 px-1 shadow-none ${
                    errors.confirmPassword ? 'border-danger' : ''
                  } `}
                  placeholder={'Enter Confirm Password'}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <div className="text-center mt-2">
              <Button
                type="submit"
                style={{ backgroundColor: theme.primaryColor, width: '100%' }}
                className="text-white ft-14 font-weight-600  mt-2"
              >
                {'Reset Password'}
              </Button>
            </div>
            <div className="text-start mt-2">
              <span className="mb-2">Have user id and pass?</span>
              <Button
                variant="link"
                style={{ color: theme.primaryColor }}
                onClick={() => updatePageState(LogInPageState.NORMAL)}
              >
                Log In
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ReSetPassComponent;
