export const monoTheme = {
  primaryColor: '#234874',
  secondaryColor: '#2047EA',
  themePrimary: '#234874',
  themeSecondary: '#2047EA',
  themeColor: '#234874',
  white: '#ffffff',
  borderColor: '#000000',
  black: '#000000',
};
