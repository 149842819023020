import { useTranslation } from 'react-i18next';

const BackgroundComponent = () => {
  const { t } = useTranslation();

  return (
    <div
      className="circle--wrapper"
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <img
        src="/images/circle_0.svg"
        height="380"
        className="login-circle-0 position-absolute"
        alt=""
      />
      <img
        src="/images/circle_1.svg"
        height="200"
        className="login-circle-1 position-absolute"
        alt=""
      />
      <img
        src="/images/circle_2.svg"
        height="360"
        className="login-circle-2 position-absolute"
        alt=""
      />
      <img
        src="/images/circle_3.svg"
        className="login-circle-3 position-absolute"
        alt=""
      />
    </div>
  );
};

export default BackgroundComponent;
